<template>
  <base-modal-view v-show="loaded" title="What's new in iAppraise?" :wide="true" @cancel="cancelClicked">
    <div class="flex-column">
      <div class="image-container">
        <img @load="onLoad()" src="/assets/img/splash1.jpg" alt="Tender Splash" />
        <div class="main-text">
          <p class="title">AutoGrab Integration</p>
        </div>
        <p class="trail">An unprecedented look into the entire automotive market.</p>
      </div>
      <div class="text-container">
        <div class="flex-row">
          <img class="hook-image" src="/assets/img/icon-car.png" alt="car" />
          <p class="hook-text">
            An advanced matching AI does the work to verify and match all cars to the unique vehicle code, so no more
            guesswork.
          </p>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <img class="hook-image" src="/assets/img/icon-dollar.png" alt="car" />
          <p class="hook-text">
            Our pricing algorithm calculates the most reliable retail and wholesale prices using market-wide data from
            every vehicle listed online..
          </p>
        </div>
      </div>
      <div class="contact-container flex-column">
        <p class="contact-text">See what iAppraise can do for you</p>
        <primary-button :title="'Talk to our sales team'" v-on:click="goToContact()" />
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";

export default {
  name: "AdvertisingAutograbModal",
  components: { BaseModalView, PrimaryButton },
  data: function () {
    return {
      loaded: false
    };
  },
  methods: {
    onLoad: function () {
      this.loaded = true;
    },
    goToContact: function () {
      this.$router.push({ name: "ContactUs" });
    },
    cancelClicked: function () {
      this.$emit("onCancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.image-container {
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 45vh;
  }

  .main-text {
    position: absolute;
    top: 10%;
    left: 24px;
    width: 25%;

    .title {
      @extend .h3-title;
      color: white;
    }

    .subtitle {
      @extend .h3-subtitle;
      color: white;
    }
  }

  .trail {
    @extend .h3-subtitle;

    position: absolute;
    bottom: 20%;
    right: 24px;
    max-width: 25%;
    color: white;
  }
}

.text-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  margin-top: 24px;
}

.hook-text {
  @extend .body-1;
  color: #262626;
}

.hook-image {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.contact-container {
  margin: auto;
  margin-top: 24px;

  .contact-text {
    @extend .body-2;
    @extend .margin-bottom-05;
    text-align: center;
  }
}

.no-underline {
  text-decoration: none;
  margin: auto;
}
</style>
