<template>
  <base-modal-view title="Trade Price Calculator" @cancel="cancelClicked">
    <label class="text-field-title margin-top">Retail Price</label>
    <input
      autocomplete="do-not-autofill"
      v-model="retailCost"
      class="margin-top-025 text-field"
      type="number"
      placeholder="Retail Price"
    />

    <label class="text-field-title margin-top-075 margin-bottom-025">Yard Fee</label>
    <input autocomplete="do-not-autofill" v-model="yardFee" class="text-field" type="number" placeholder="Yard Fee" />

    <label class="text-field-title margin-top-075 margin-bottom-025"
      >Reco Costs (Reco costs don't get saved, they will be calculated every time by the damages recorded on the
      appraisal)</label
    >
    <input
      autocomplete="do-not-autofill"
      v-model="recoCosts"
      class="text-field"
      type="number"
      placeholder="Reco Costs"
    />
    <label class="text-field-title margin-top-025"
      >*Reco costs don't get saved, they will be calculated every time by the damages recorded on the appraisal</label
    >

    <label class="text-field-title margin-top-075 margin-bottom-025">Margin</label>
    <input autocomplete="do-not-autofill" v-model="margin" class="text-field" type="number" placeholder="Margin" />

    <label class="text-field-title margin-top-075 margin-bottom-025">On Road Costs</label>
    <input
      autocomplete="do-not-autofill"
      v-model="onRoadCosts"
      class="text-field"
      type="number"
      placeholder="On Road Costs"
    />
    <hr />
    <div class="flex-row body-2">
      <p class="body-2-bold margin-right-025 no-wrap">Suggested Trade Price:</p>
      <p v-if="retailCost && yardFee && recoCosts != null && margin && onRoadCosts">
        ${{ retailCost - yardFee - recoCosts - margin - onRoadCosts }}
      </p>
      <secondary-button
        v-if="form && retailCost && yardFee && recoCosts != null && margin && onRoadCosts"
        class="margin-left-auto"
        title="Save"
        v-on:click="saveClicked()"
      />
      <p v-if="!(retailCost && yardFee && recoCosts != null && margin && onRoadCosts)">
        Fill out all fields to calculate the Suggested Trade Price
      </p>
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import SecondaryButton from "../../Buttons/v2/SecondaryButton.vue";
import { updateFormTPCFields } from "../../../api/Form.js";
import { mapActions } from "vuex";

export default {
  name: "TradePriceCalculator",
  components: { BaseModalView, SecondaryButton },
  props: ["form", "damages"],
  data: function () {
    return {
      retailCost: null,
      yardFee: null,
      recoCosts: null,
      onRoadCosts: null,
      margin: null
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    saveClicked: function () {
      updateFormTPCFields(this.form, this.retailCost, this.yardFee, this.onRoadCosts, this.margin)
        .then(response => {
          this.$emit("formUpdated", response);
          this.$emit("cancel");
        })
        .catch(error => {
          this.addError(error);
        });
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    var damageCosts = 0;
    if (this.form) {
      if (this.form.body_damages) {
        this.form.body_damages.forEach(damage => {
          damageCosts += damage.cost;
        });
      }
      this.recoCosts = damageCosts;
      this.retailCost = this.form.tpc_retail_price;
      this.yardFee = this.form.tpc_yard_fee;
      this.onRoadCosts = this.form.tpc_on_road_costs;
      this.margin = this.form.tpc_margin;
    } else if (this.damages) {
      this.damages.forEach(damage => {
        damageCosts += damage.cost;
      });
      this.recoCosts = damageCosts;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.no-wrap {
  white-space: nowrap;
}
</style>
