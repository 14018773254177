<template>
  <div>
    <div class="flex-row margin-bottom-1-05">
      <img src="/assets/img/logo-autograb.svg" class="icon margin-right-025" style="object-fit: contain" />
      <p class="h3-title flex-row vertical-flex-center" style="max-width: 100%">Autograb Intelligence</p>
      <secondary-button
        v-if="!hideButtons && !(currentDealer.has_autograb_access || currentDealership.is_autograb_account)"
        class="margin-left-auto"
        title="Available Now"
        v-on:click="showAutoGrabAdvertising = true"
      />
      <div class="position-relative margin-left-auto">
        <secondary-button
          v-if="!hideButtons && (currentDealer.has_autograb_access || currentDealership.is_autograb_account)"
          :title="fetchingMarketIntelligence || fetchingVehicleReport ? 'Loading...' : 'Generate'"
          :selected="showGenerateDropdown"
          v-on:click="showGenerateDropdown = !showGenerateDropdown"
        />
        <div v-if="showGenerateDropdown" class="iappraise-dropdown">
          <div class="iappraise-dropdown-button" v-on:click="fetchMarketIntelligenceUsingRego()">
            {{ fetchingMarketIntelligence ? "Fetching..." : auto_grab_fetched_data ? "Reprice Now" : "Price Now" }}
          </div>
          <div class="iappraise-dropdown-button" v-on:click="fetchAutoGrabVehicleReportForForm()">
            {{
              fetchingVehicleReport
                ? "Generating..."
                : auto_grab_fetched_data &&
                  auto_grab_fetched_data.latest_report &&
                  auto_grab_fetched_data.latest_report.vehicle_report_pdf_url
                ? "Regenerate Report"
                : "Car Analysis Report"
            }}
          </div>
        </div>
      </div>
    </div>
    <p
      class="error"
      v-if="fetchingVehicleReport || (auto_grab_fetched_data && auto_grab_fetched_data.vehicle_report_pdf_generating)"
    >
      The Car Analysis Report is currently generating. This can take up to 60 seconds. You can leave and come back to
      this page to find the report later, or you can visit the Live Pricing tab at a later point.
    </p>
    <table
      v-if="auto_grab_fetched_data && (currentDealer.has_autograb_access || currentDealership.is_autograb_account)"
      class="form-table"
    >
      <tr v-if="auto_grab_fetched_data.confidence">
        <td class="heading-td">Confidence</td>
        <td class="content-td capitalize">{{ auto_grab_fetched_data.confidence }}</td>
      </tr>
      <tr>
        <td class="heading-td">Advised Trade Price</td>
        <td class="content-td">${{ Number(auto_grab_fetched_data.trade_price).toLocaleString() }}</td>
      </tr>
      <tr>
        <td v-on:click="showAdjustmentDropdown = !showAdjustmentDropdown" class="pointer-cursor heading-td">
          Adjusted Advised Trade Price
        </td>
        <td class="content-td position-relative">
          ${{ adjustedAdvisedTradePrice() }} ({{ auto_grab_fetched_data.adjusted_trade_price_percentage }}%)
          <input-drop-box
            v-if="showAdjustmentDropdown"
            title="Adjustment Percentage"
            type="number"
            units="%"
            @onSubmit="adjustAdvisedTradePrice"
            @onClose="showAdjustmentDropdown = false"
          />
        </td>
      </tr>
      <tr v-if="recoCosts() > 0">
        <td class="heading-td">Reco Cost Adjusted Advised Trade Price</td>
        <td class="content-td">${{ (adjustedAdvisedTradePrice() - recoCosts()).toFixed(2) }}</td>
      </tr>
      <tr>
        <td class="autograb-label">Advised Retail Price</td>
        <td class="text-right autograb-label">${{ Number(auto_grab_fetched_data.retail_price).toLocaleString() }}</td>
      </tr>
      <tr v-if="auto_grab_fetched_data.price_when_new">
        <td class="heading-td">Price When New</td>
        <td class="content-td">${{ Number(auto_grab_fetched_data.price_when_new).toLocaleString() }}</td>
      </tr>
      <tr v-if="auto_grab_fetched_data.competitors">
        <td class="heading-td">Competitors</td>
        <td class="content-td">{{ Number(auto_grab_fetched_data.competitors).toLocaleString() }}</td>
      </tr>
      <tr v-if="auto_grab_fetched_data.avg_days_to_sell">
        <td class="autograb-label">Average Days to Sell</td>
        <td class="content-td autograb-label">
          {{ Number(auto_grab_fetched_data.avg_days_to_sell).toLocaleString() }} (Last
          {{ auto_grab_fetched_data.days_checked }} days)
        </td>
      </tr>
      <tr v-if="auto_grab_fetched_data.vehicle_mileage">
        <td class="heading-td">Car Mileage</td>
        <td class="content-td">
          {{
            auto_grab_fetched_data.vehicle_mileage
              ? Number(auto_grab_fetched_data.vehicle_mileage).toLocaleString()
              : ""
          }}kms
        </td>
      </tr>
      <tr>
        <td class="heading-td">Fetched</td>
        <td class="content-td">{{ dateToDayMonthYearTimeReadableString(auto_grab_fetched_data.date_requested) }}</td>
      </tr>
    </table>

    <div
      class="flex-row vertical-spacing-top-half margin-top-05"
      v-if="!hideButtons && (currentDealer.has_autograb_access || currentDealership.is_autograb_account)"
    >
      <div class="margin-left-auto flex-row">
        <secondary-button
          icon="/assets/img/logo-autograb.svg"
          title="View on Autograb"
          v-if="
            auto_grab_fetched_data &&
            currentDealer.has_autograb_whitelabel_permissions &&
            auto_grab_fetched_data.whitelabel_url
          "
          v-on:click="goToAutograbWhiteLabel()"
        />
        <secondary-button
          class="margin-left-05"
          title="Your History"
          v-if="auto_grab_fetched_data && form.car_model_code && form.car_model_year"
          v-on:click="goToModelCodeSearch()"
        />

        <secondary-button
          class="margin-left-05"
          title="The Market"
          v-if="auto_grab_fetched_data"
          v-on:click="goToGlobalMarket()"
        />

        <secondary-button
          class="margin-left-05"
          title="Open Report"
          v-if="
            auto_grab_fetched_data &&
            auto_grab_fetched_data.latest_report &&
            auto_grab_fetched_data.latest_report.vehicle_report_pdf_url &&
            !auto_grab_fetched_data.vehicle_report_pdf_generating
          "
          v-on:click="goToReport()"
        />
      </div>
    </div>

    <auto-grab-vehicles-modal
      v-if="autograbVehicles"
      :vehicles="autograbVehicles"
      @cancel="autograbVehicles = null"
      @submit="fetchMarketIntelligence"
    />

    <advertising-autograb-modal v-if="showAutoGrabAdvertising" @onCancel="showAutoGrabAdvertising = false" />
  </div>
</template>

<script>
import { dateToDayMonthYearTimeReadableString } from "../../../helpers/utilityHelpers.js";
import { mapState, mapActions } from "vuex";

import {
  requestAutoGrabFetchData,
  requestAutoGrabVehicles,
  requestAutoGrabFetchDataUsingRego,
  requestAutoGrabVehicleReportForForm
} from "../../../api/Form.js";

import { refreshAGFD, updateAGFDAdjustment } from "../../../api/GlobalMarket.js";

import SecondaryButton from "../../Buttons/v2/SecondaryButton.vue";
import AdvertisingAutograbModal from "../../Modals/v2/AdvertisingAutograbModal.vue";
import AutoGrabVehiclesModal from "../../Modals/v2/AutoGrabVehiclesModal.vue";
import InputDropBox from "../../Common/InputDropBox.vue";

export default {
  name: "AutograbBox",
  props: ["form", "auto_grab_fetched_data", "hideButtons", "verticalHeading"],
  components: {
    AutoGrabVehiclesModal,
    AdvertisingAutograbModal,
    InputDropBox,
    SecondaryButton
  },
  data: function () {
    return {
      refreshingAGFD: false,
      fetchingMarketIntelligence: false,
      fetchingVehicleReport: false,
      autograbVehicles: null,
      showGenerateDropdown: false,
      showAdjustmentDropdown: false,
      error: [],
      showAutoGrabAdvertising: false,
      timer: null
    };
  },
  methods: {
    refreshAGFDUntilNewReport: function () {
      this.refreshingAGFD = true;
      refreshAGFD(this.auto_grab_fetched_data)
        .then(response => {
          if (
            this.auto_grab_fetched_data &&
            response.vehicle_report_pdf_generating != this.auto_grab_fetched_data.vehicle_report_pdf_generating
          ) {
            this.$emit("newAutoGrabData", response);
          }
          clearInterval(this.timer);
          if (response.vehicle_report_pdf_generating) {
            this.timer = setInterval(() => {
              this.refreshAGFDUntilNewReport();
            }, 5 * 1000);
          } else {
            this.refreshingAGFD = false;
            window.open(response.latest_report.vehicle_report_pdf_url);
          }
        })
        .catch(error => {
          this.refreshingAGFD = false;
          this.addError(error);
        });
    },
    dateToDayMonthYearTimeReadableString(dateString) {
      return dateToDayMonthYearTimeReadableString(dateString);
    },
    fetchMarketIntelligenceID() {
      if (this.fetchingMarketIntelligence) {
        return;
      }
      this.fetchingMarketIntelligence = true;
      requestAutoGrabVehicles(this.form)
        .then(response => {
          this.fetchingMarketIntelligence = false;
          this.autograbVehicles = response;
        })
        .catch(error => {
          this.fetchingMarketIntelligence = false;
          this.addError(error);
        });
    },
    fetchMarketIntelligence(vehicleId) {
      if (this.fetchingMarketIntelligence) {
        return;
      }
      this.fetchingMarketIntelligence = true;
      this.autograbVehicles = null;
      requestAutoGrabFetchData(this.form, vehicleId)
        .then(response => {
          this.fetchingMarketIntelligence = false;
          this.$emit("newAutoGrabData", response);
        })
        .catch(error => {
          this.fetchingMarketIntelligence = false;
          this.addError(error);
        });
    },
    fetchMarketIntelligenceUsingRego() {
      this.showGenerateDropdown = false;
      if (this.fetchingMarketIntelligence) {
        return;
      }
      this.fetchingMarketIntelligence = true;
      requestAutoGrabFetchDataUsingRego(this.form)
        .then(response => {
          this.fetchingMarketIntelligence = false;
          this.$emit("newAutoGrabData", response);
        })
        .catch(error => {
          this.fetchingMarketIntelligence = false;
          console.log(error);
          this.addError(
            "There was an error fetching this vehicle from its Registration Number. We will try and find one for you based on the vehicle info."
          );
          this.fetchMarketIntelligenceID();
        });
    },
    fetchAutoGrabVehicleReportForForm() {
      this.showGenerateDropdown = false;
      this.fetchingVehicleReport = true;
      requestAutoGrabVehicleReportForForm(this.form.id)
        .then(response => {
          this.fetchingVehicleReport = false;
          this.$emit("newAutoGrabData", response);
        })
        .catch(error => {
          this.fetchingVehicleReport = false;
          this.addError(error);
        });
    },
    adjustedAdvisedTradePrice: function () {
      return (
        (this.auto_grab_fetched_data.trade_price * this.auto_grab_fetched_data.adjusted_trade_price_percentage) /
        100
      ).toFixed(2);
    },
    adjustAdvisedTradePrice: function (percentage) {
      if (Number(percentage) < 0 || Number(percentage) > 100) {
        this.addError("Please enter a percentage between 0 and 100");
        return;
      }
      updateAGFDAdjustment(this.auto_grab_fetched_data, percentage)
        .then(response => {
          this.$emit("newAutoGrabData", response);
          this.showAdjustmentDropdown = false;
        })
        .catch(error => {
          this.showAdjustmentDropdown = false;
          this.addError(error);
        });
    },
    goToReport: function () {
      window.open(this.auto_grab_fetched_data.latest_report.vehicle_report_pdf_url, "_blank");
    },
    goToGlobalMarket: function () {
      let route = this.$router.resolve({
        name: "GlobalMarket",
        params: { formId: this.form.id }
      });
      window.open(route.href, "_blank");
    },
    goToModelCodeSearch: function () {
      let route = this.$router.resolve({
        name: "ModelCode",
        params: { mc: this.form.car_model_code, year: this.form.car_model_year }
      });
      window.open(route.href, "_blank");
    },
    goToAutograbWhiteLabel: function () {
      window.open(this.auto_grab_fetched_data.whitelabel_url, "_blank");
    },
    recoCosts: function () {
      if (this.form) {
        return this.form.body_damages.reduce((acc, damage) => acc + damage.cost, 0);
      }
      return 0;
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  watch: {
    auto_grab_fetched_data: function (newData) {
      if (newData && newData.vehicle_report_pdf_generating) {
        this.refreshAGFDUntilNewReport();
      }
    },
    form: function () {
      clearInterval(this.timer);
    }
  },
  computed: {
    has_autograb_vr_access: function () {
      return this.currentDealership.is_autograb_vr_account || this.currentDealer.has_autograb_vr_access;
    },
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.autograb-label {
  @extend .heading-td;
  color: $BLUE_COLOR;
}

.iappraise-dropdown {
  left: auto;
}
</style>
