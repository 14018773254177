<template>
  <base-modal-view title="Select the Best Match" @cancel="cancelClicked">
    <autograb-vehicles-table :vehicles="vehicles" @selectedVehicle="selectedVehicle" />
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import AutograbVehiclesTable from "../../Views/AutograbVehiclesTable.vue";

export default {
  name: "AutoGrabVehiclesModal",
  components: { BaseModalView, AutograbVehiclesTable },
  props: {
    vehicles: {
      type: Array
    }
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    selectedVehicle(vehicle) {
      this.$emit("submit", vehicle.id);
    }
  }
};
</script>
